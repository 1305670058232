'use client';

import { rudderanalyticsIdentify } from '@/libs/rudderstack';
import { useEffect } from 'react';
import type AdjustType from '@adjustcom/adjust-web-sdk';

// Note that environment variables cannot be destructured for client-side usage
// due to the way Next.js handles environment variable exposure. Client-side
// environment variables must be explicitly accessed through `process.env`
// with the appropriate `NEXT_PUBLIC_` prefix.
const ADJUST_APP_TOKEN = process.env.NEXT_PUBLIC_ADJUST_APP_TOKEN;
const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV;
const NODE_ENV = process.env.NODE_ENV;
let initialized = false;

declare global {
  interface Window {
    Adjust: typeof AdjustType | undefined;
  }
}

export async function initializeAdjust() {
  if (
    ADJUST_APP_TOKEN &&
    typeof window !== 'undefined' &&
    window.Adjust &&
    !initialized
  ) {
    initialized = true;
    const isDev = NODE_ENV === 'development' || APP_ENV === 'dev';

    window.Adjust.initSdk({
      appToken: ADJUST_APP_TOKEN,
      environment: isDev ? 'production' : 'sandbox',
      logLevel: isDev ? 'verbose' : 'error'
    });

    try {
      window.Adjust.waitForWebUUID().then((webUUID) => {
        rudderanalyticsIdentify({
          adjustWebUUID: webUUID
        });
      });
    } catch {}
  }
}

export function InitializeAdjust() {
  useEffect(() => {
    void initializeAdjust();
  }, []);

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}

export const AdjustScript = `
(function(window, document, tag, a, n, o, namespace, methods, classes, realObjKey, addToQueue) {
    const queueKey = namespace + "_q";
    const constructorKey = namespace + "_c";

    window[namespace] = window[namespace] || {};
    window[queueKey] = window[queueKey] || [];
    window[constructorKey] = window[constructorKey] || [];

    // Register methods for queueing
    for (let i = 0; i < methods.length; i++) {
        addToQueue(window[namespace], window[queueKey], methods[i]);
    }

    // Register classes and their methods
    for (let i = 0; i < classes.length; i++) {
        const className = classes[i][0];
        const classMethods = classes[i][1];
        let instance;

        window[namespace][className] = function(...args) {
            instance = this;
            window[constructorKey].push(() => {
                instance[realObjKey] = new window[namespace][className](...args);
            });
            return instance;
        };

        for (let j = 0; j < classMethods.length; j++) {
            const method = classMethods[j];
            window[namespace][className].prototype[method] = function(...args) {
                window[constructorKey].push(() => {
                    instance[realObjKey][method](...args);
                });
            };
        }
    }

    // Load Adjust script
    const script = document.createElement(tag);
    const firstScript = document.getElementsByTagName(tag)[0];
    script.async = true;
    script.src = "https://cdn.adjust.com/adjust-latest.min.js";

    script.onload = function() {
        // Run constructor queue
        for (let i = 0; i < window[constructorKey].length; i++) {
            window[constructorKey][i]();
        }
        window[constructorKey] = [];

        // Run method queue
        for (let i = 0; i < window[queueKey].length; i++) {
            const [methodName, args] = window[queueKey][i];
            if (args[0][realObjKey]) {
                window[namespace][methodName](args[0][realObjKey]);
            } else {
                window[namespace][methodName].apply(window[namespace], args);
            }
        }
        window[queueKey] = [];
    };

    firstScript.parentNode.insertBefore(script, firstScript);

})(
    window,
    document,
    "script",
    0,
    0,
    0,
    "Adjust",
    [
        "initSdk", "getAttribution", "getWebUUID", "waitForAttribution",
        "waitForWebUUID", "setReferrer", "trackEvent", "addGlobalCallbackParameters",
        "addGlobalPartnerParameters", "removeGlobalCallbackParameter", "removeGlobalPartnerParameter",
        "clearGlobalCallbackParameters", "clearGlobalPartnerParameters", "switchToOfflineMode",
        "switchBackToOnlineMode", "stop", "restart", "gdprForgetMe", "disableThirdPartySharing",
        "trackThirdPartySharing", "initSmartBanner", "showSmartBanner", "hideSmartBanner"
    ],
    [
        ["ThirdPartySharing", ["addGranularOption", "addPartnerSharingSetting"]]
    ],
    "__realObj",
    function(obj, queue, method) {
        obj[method] = function() {
            queue.push([method, arguments]);
        };
    }
);
`;
