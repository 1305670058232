'use client';

import { useAuthStore } from '@/stores/auth-store-provider';
// Note that environment variables cannot be destructured for client-side usage
// due to the way Next.js handles environment variable exposure. Client-side
// environment variables must be explicitly accessed through `process.env`
// with the appropriate `NEXT_PUBLIC_` prefix.
import { useEffect } from 'react';

const BRANCH_KEY = process.env.NEXT_PUBLIC_BRANCH_KEY;
let initialized = false;

export async function initializeBranchIO() {
  if (
    BRANCH_KEY &&
    typeof window !== 'undefined' &&
    window.branch &&
    !initialized
  ) {
    initialized = true;
    window.branch.init(BRANCH_KEY);
  }
}

export function InitializeBranchIO() {
  const authStore = useAuthStore();
  const user = authStore.useTracked.user();

  useEffect(() => {
    void initializeBranchIO();
  }, []);

  useEffect(() => {
    if (window.branch && user) {
      const userId = user?.['custom:uuid'] || user?.id;

      window.branch.setIdentity(userId);
    }
  }, [user]);

  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}

export const BranchIOScript = `
(function(window, document, scriptType, branchObjName, registerFn, branchObj, methodNames, index, scriptEl, firstScriptEl) {
    if (!window[branchObjName] || !window[branchObjName]._q) {
        for (; index < methodNames.length;) {
            registerFn(branchObj, methodNames[index++]);
        }

        scriptEl = document.createElement(scriptType);
        scriptEl.async = 1;
        scriptEl.src = "https://cdn.branch.io/branch-latest.min.js";
        firstScriptEl = document.getElementsByTagName(scriptType)[0];
        firstScriptEl.parentNode.insertBefore(scriptEl, firstScriptEl);

        window[branchObjName] = branchObj;
    }
})(
    window,
    document,
    "script",
    "branch",
    function(branchObj, methodName) {
        branchObj[methodName] = function() {
            branchObj._q.push([methodName, arguments]);
        };
    },
    {
        _q: [],
        _v: 1
    },
    "addListener banner closeBanner closeJourney data deepview deepviewCta first init link logout removeListener setBranchViewData setIdentity track trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData setAPIResponseCallback qrCode setRequestMetaData setAPIUrl getAPIUrl setDMAParamsForEEA".split(" "),
    0
);
`;
