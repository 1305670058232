'use client';

import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import parse, {
  attributesToProps,
  DOMNode,
  domToReact,
  HTMLReactParserOptions
} from 'html-react-parser';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import type { Element } from 'domhandler';
import sanitize from 'sanitize-html';
import { useMemo } from 'react';

/**
 * Import the `getEditorStyles` function from the 'mui-tiptap/styles' package.
 * This function is originally internal to the package and is imported via an alias
 * defined in the `tsconfig.json` paths configuration.
 */
import { getEditorStyles } from 'mui-tiptap-styles';

const allowedHtmlTags = sanitize.defaults.allowedTags.concat([
  'button',
  'img',
  'svg'
]);

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (!('name' in domNode)) return;
    const { name, attribs, children } = domNode as Element;
    const props = attributesToProps(attribs);
    switch (name) {
      case 'a':
        return (
          <Link {...props}>{domToReact(children as DOMNode[], options)}</Link>
        );
      case 'button':
        return (
          <Button {...props}>
            {domToReact(children as DOMNode[], options)}
          </Button>
        );
      case 'p':
        return (
          <Typography component="div" {...props}>
            {domToReact(children as DOMNode[], options)}
          </Typography>
        );
      default:
        return;
    }
  }
};

export function RenderHTML({
  children,
  sx,
  enablePrivacy,
  ...props
}: Omit<BoxProps<'div'>, 'children'> & {
  children?: string | null;
  enablePrivacy?: boolean;
}) {
  const sanitizedContent = useMemo(() => {
    return parse(
      sanitize(children || '', {
        allowedTags: allowedHtmlTags,
        allowedAttributes: false
      }),
      options
    );
  }, [children]);

  return (
    <Box
      {...props}
      {...(enablePrivacy && { ['data-dd-privacy']: 'mask' })}
      sx={[
        getEditorStyles,
        {
          wordBreak: 'break-word',
          // Editor do not retain empty line breaks. Fixing with css here
          // Reference: https://github.com/ueberdosis/tiptap/issues/412#issuecomment-1500873959
          '@supports (height: 1lh)': {
            'p:empty': {
              height: '1lh'
            },
            'div:empty': {
              height: '1lh'
            }
          },
          '@supports not (height: 1lh)': {
            'p:empty::after': {
              content: '"\\00A0"'
            },
            'div:empty::after': {
              content: '"\\00A0"'
            }
          },
          '& img:not(.ProseMirror-separator)': {
            backgroundColor: 'transparent',
            color: 'transparent'
          }
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {sanitizedContent}
    </Box>
  );
}
