import { rudderanalyticsIdentify } from '@/libs/rudderstack';
import { AuthStoreProvider, useAuthStore } from '@/stores/auth-store-provider';
import { useSetCallbackUrlAndRedirect } from '@/utils/redirect';
import { Session } from 'next-auth';
// eslint-disable-next-line no-restricted-imports
import { SessionProvider, useSession } from 'next-auth/react';
import { ReactNode, useEffect } from 'react';

function AuthProviderListener() {
  const { status, data: session } = useSession();
  const authStore = useAuthStore();
  const error = session?.error;
  const redirect = useSetCallbackUrlAndRedirect();

  useEffect(() => {
    (async function () {
      if (error === 'RefreshAccessTokenError') {
        redirect('/sign-out');
      }
    })();
  }, [error, redirect]);

  useEffect(() => {
    authStore.set.session(session);
  }, [session, authStore]);

  useEffect(() => {
    switch (status) {
      case 'authenticated':
        if (session) {
          authStore.set.session(session);
        }
        break;
      case 'unauthenticated':
        authStore.set.reset();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return null;
}

export type AuthProviderProps = {
  session: Session | null;
  children?: ReactNode;
};

export function AuthProvider({ session, children }: AuthProviderProps) {
  return (
    <SessionProvider
      session={session}
      refetchOnWindowFocus={false}
      refetchWhenOffline={false}
    >
      <AuthStoreProvider session={session}>
        <AuthProviderListener />
        {children}
      </AuthStoreProvider>
    </SessionProvider>
  );
}
