import CloseIcon from '@mui/icons-material/CloseOutlined';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import {
  closeSnackbar,
  InternalSnack,
  SnackbarKey,
  SnackbarProvider
} from 'notistack';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

const SnackbarComponent = forwardRef(
  (
    {
      id,
      variant,
      message,
      action: actionOrFunction,
      icon
    }: InternalSnack & { icon?: ReactNode },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const action =
      typeof actionOrFunction === 'function'
        ? actionOrFunction(id)
        : actionOrFunction;

    return (
      <Alert
        ref={ref}
        variant={['error', 'warning'].includes(variant) ? 'filled' : 'standard'}
        severity={variant as any}
        action={action}
        icon={icon}
        sx={{ fontWeight: 500, fontFamily: 'inherit' }}
      >
        {message}
      </Alert>
    );
  }
);

SnackbarComponent.displayName = 'SnackbarComponent';

const action = (key: SnackbarKey) => (
  <IconButton
    size="small"
    aria-label="close"
    color="inherit"
    onClick={() => closeSnackbar(key)}
  >
    <CloseIcon fontSize="small" />
  </IconButton>
);

export function AppSnackbarProvider() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={10000}
      maxSnack={3}
      dense
      Components={{
        success: SnackbarComponent,
        warning: SnackbarComponent,
        error: SnackbarComponent,
        info: SnackbarComponent
      }}
      action={action}
    />
  );
}
