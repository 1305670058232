import { useCleanPathname } from '@/hooks/use-clean-pathname';
import { useEffect } from 'react';

export function HashDeepLinks() {
  const pathname = useCleanPathname();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleDeepLink = () => {
      const hash = window.location.hash.slice(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    handleDeepLink();
  }, [pathname]);

  return null;
}
