'use client';

import urlJoin from 'proper-url-join';
import { useEffect, ReactNode } from 'react';
import {
  StatsigProvider as StatsigSDKProvider,
  useClientBootstrapInit
} from '@statsig/react-bindings';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { StatsigSetupData } from './types';

const statsigProxyUrl =
  process.env.NEXT_PUBLIC_STATSIG_PROXY_URL ||
  'https://gajqfvtr7j5ovdlpn4lkl7ed4q0zxltp.lambda-url.ap-south-1.on.aws';

export function StatsigProvider({
  children,
  statsigSetupData
}: {
  children: ReactNode;
  statsigSetupData: StatsigSetupData;
}) {
  const client = useClientBootstrapInit(
    statsigSetupData.clientKey,
    statsigSetupData.user,
    statsigSetupData.data,
    {
      networkConfig: {
        api: urlJoin(statsigProxyUrl, 'v1')
      },
      environment: {
        tier: statsigSetupData.tier
      }
    }
  );

  useEffect(() => {
    runStatsigAutoCapture(client);
  }, [client]);

  return <StatsigSDKProvider client={client}>{children}</StatsigSDKProvider>;
}
