import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/home/runner/work/client/client/apps/web/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/client/client/apps/web/src/app/[lang]/global.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/client/client/apps/web/src/app/[lang]/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppLoadingBar"] */ "/home/runner/work/client/client/apps/web/src/components/app-loading-bar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdjustScript"] */ "/home/runner/work/client/client/apps/web/src/libs/adjust/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["BranchIOScript"] */ "/home/runner/work/client/client/apps/web/src/libs/branch.io/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["FirstPromoterScript"] */ "/home/runner/work/client/client/apps/web/src/libs/firstpromoter/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RudderStackScript"] */ "/home/runner/work/client/client/apps/web/src/libs/rudderstack/index.ts");
